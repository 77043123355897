/* eslint-disable consistent-return */
// Lib dependencies
import { useNavigate } from 'react-router-dom';

// Others
import useStore from '../shared/store';

/* -------------------------------------------------------------------------- */
/*                                    Hook                                    */
/* -------------------------------------------------------------------------- */

const useRouterNavigation = () => {
  const { brand, entity } = useStore();

  const navigate = useNavigate();

  /**
   * Navigates to the specified path.
   *
   * @param {string} targetPath - The path to navigate to.
   */
  const navigateTo = (targetPath) => {
    if (targetPath === '/404') {
      return navigate(targetPath);
    }

    const brandPath = brand.path ? `/${brand.path}` : '';
    const queryParam = entity.isLoaded ? `?UID=${entity.data.reference}` : '';
    const fullPath = `${brandPath}${targetPath}${queryParam}`;

    navigate(fullPath);
  };

  /**
   * Navigates back to the previous page.
   */
  const navigateBack = () => {
    navigate(-1);
  };

  /**
   * Opens the specified URL in a new tab.
   *
   * @param {string} targetPath - The URL to open in a new tab.
   */
  const openNewTab = (targetPath) => {
    window.open(targetPath, '_blank');
  };

  return { navigateTo, navigateBack, openNewTab };
};

export default useRouterNavigation;
