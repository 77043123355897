/* eslint-disable consistent-return */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// React
import { useState, useEffect } from 'react';

// Lib dependencies
import { useSearchParams } from 'react-router-dom';

// Componentes
import LoadingOverlay from '../../shared/components/organisms/LoadingOverlay';

// Services
import CategoryServices from '../../shared/services/category.service';
import ProductServices from '../../shared/services/product.service';

// Configs
import routesPaths from '../../router/configs/routesPaths.configs';

// Hooks
import useRouterNavigation from '../../hooks/useRouterNavigation';

// Others
import useStore from '../../shared/store';

/* -------------------------------------------------------------------------- */
/*                                  Provider                                  */
/* -------------------------------------------------------------------------- */

const EntityProvider = (props) => {
  /* ******************************** LOGIC ******************************* */

  const { navigateTo } = useRouterNavigation();

  const [isLoading, setIsLoading] = useState(true);

  const [searchParams] = useSearchParams();

  const { children } = props;

  const { loadEntity } = useStore();

  useEffect(() => {
    // Get product data from api
    const loadProduct = async (reference) => {
      try {
        // Scan service
        const response = await ProductServices.scan(reference);

        loadEntity({ isLoaded: true, type: 'product', data: response });
        setIsLoading(false);
      } catch (error) {
        // Handling api errors

        switch (error?.response?.data?.errorCode) {
          // Scanned product is already certified
          case 9002: {
            // Verify service
            const response = await ProductServices.verify(reference);

            loadEntity({ isLoaded: true, type: 'product', data: response });
            setIsLoading(false);

            break;
          }
          // All api errors except 9002
          default:
            navigateTo(routesPaths.notFound);
        }
      }
    };

    // Get category data from api
    const loadCategory = async (reference) => {
      try {
        // Scan service
        const response = await CategoryServices.scan(reference);

        loadEntity({ isLoaded: true, type: 'category', data: response });
        setIsLoading(false);
      } catch (error) {
        // Handling api errors

        switch (error?.response?.data?.errorCode) {
          // Scanned category is not found
          case 14000: {
            // Get product data
            await loadProduct(reference);
            break;
          }

          // All api errors except 14000
          default:
            navigateTo(routesPaths.notFound);
        }
      }
    };

    const reference = searchParams.get('UID');

    if (reference) {
      loadCategory(reference);
      return;
    }

    setIsLoading(false);
  }, []);

  /* ******************************** RENDERING ******************************* */

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  return children;
};

export default EntityProvider;
