/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import classNames from 'classnames';
import { useMediaQuery } from '@mantine/hooks';
import { useMantineTheme } from '@mantine/core';

// Style
import './MessageModal.scss';

// component
import Typography from '../../atoms/Typography';
import Button from '../../atoms/Button';
import Image from '../../atoms/Image';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

const MessageModal = (props) => {
  /* ******************************** LOGIC ******************************* */

  const { context, id, innerProps } = props;

  const { subTitle, imageSrc, message, buttonText, onClose } = innerProps;

  const theme = useMantineTheme();

  const isMobileOrTablet = useMediaQuery(`(max-width:${theme.breakpoints.smDesktop}px )`);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    context.closeModal(id);
  };

  /* ******************************** RENDERING ******************************* */

  return (
    <div className="wrapper">
      {/* SubTitle */}
      <div className="wrapper__subtitle-ctn">
        <Typography type="subtitle" mb={isMobileOrTablet ? '10px' : '12px'}>
          {subTitle}
        </Typography>
      </div>

      {/* Message */}
      <Typography type="text" mb="20px">
        {message}
      </Typography>

      {/* Image */}
      <div className="wrapper__image-ctn">
        <Image src={imageSrc} width={200} height={300} alt="UID Example" />
      </div>

      {/* Close button */}
      <div className={classNames('wrapper__btn-ctn', 'centred')}>
        <Button disableMarginBottom onClick={handleClose}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

/* -------------------------------------------------------------------------- */
/*                                    Props                                   */
/* -------------------------------------------------------------------------- */

export default MessageModal;
